import React from 'react'

const BannerResearch = (props) => (
    <section id="bannercv" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Research</h1>
            </header>
            <div className="content">
                <p>
                    
                </p>

            </div>
        </div>
    </section>
)

export default BannerResearch
